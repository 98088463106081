@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600&family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@font-face {
  font-family: "NanumSquareNeo";
  src: url("../public/fonts/NanumSquareNeoTTF-aLt.woff2");
  font-weight: 300;
}
@font-face {
  font-family: "NanumSquareNeo";
  src: url("../public/fonts/NanumSquareNeoTTF-bRg.woff2");
  font-weight: 400;
}
@font-face {
  font-family: "NanumSquareNeo";
  src: url("../public/fonts/NanumSquareNeoTTF-cBd.woff2");
  font-weight: 700;
}
@font-face {
  font-family: "NanumSquareNeo";
  src: url("../public/fonts/NanumSquareNeoTTF-dEb.woff2");
  font-weight: 800;
}

@layer base {
  body {
    color: #131313;

    -ms-overflow-style: none; /* IE and Edge */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* Firefox */
  }

  body::-webkit-scrollbar {
    display: none;
  }
}
